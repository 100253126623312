import { render, staticRenderFns } from "./UtilsCheckButton.vue?vue&type=template&id=0c7cee40&scoped=true&"
import script from "./UtilsCheckButton.vue?vue&type=script&lang=js&"
export * from "./UtilsCheckButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c7cee40",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Check: require('/vercel/path0/components/utils/Check.vue').default,UtilsButton: require('/vercel/path0/components/utils/UtilsButton.vue').default})
