//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      localValue: this.value
    }
  },
  watch: {
    value (newVal) {
      if (this.localValue === newVal) { return }
      this.localValue = newVal
    },
    localValue (newVal) {
      this.$emit('change', newVal)
    }
  },
  methods: {
    toggleCheckbox () {
      this.localValue = !this.localValue
    }
  }
}
